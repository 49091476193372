<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="5" class="text-center">
      <h2 class="mt-5">Hai problemi con la tua linea?</h2>
      <p class="mt-3 mb-10">
        Non temere, un agente può assisterti come se fosse lì con te.<br />
        Contattaci e ti aiuteremo a risolvere qualsiasi tua problematica.
      </p>
    </v-col>
  </v-row>
</template>

<script>
import {
  isMobile,
  isAndroid,
  isWindows,
  isMacOs,
  isIOS,
} from 'mobile-device-detect'
export default {
  name: 'Home',
  data: () => ({
    isMobile,
    isAndroid,
    isIOS,
    isWindows,
    isMacOs,
  }),
}
</script>

<style scoped></style>
